/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { Injectable } from '@angular/core';
import { Observable, fromEvent, map, merge } from 'rxjs';

type ImageLoadResult = { src: null; error: true } | { src: string; error: null };

@Injectable({ providedIn: 'root' })
export class ImageLoader {
  loadImage(src: string): Observable<ImageLoadResult> {
    const img = document.createElement('img');
    const error = fromEvent(img, 'error').pipe(map(() => ({ error: true, src: null }) as const));
    const success = fromEvent(img, 'load').pipe(map(() => ({ error: null, src: img.src }) as const));
    img.src = src;
    return merge(error, success);
  }
}
